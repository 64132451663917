<template>
    <div class='auth-wrapper auth-v2'>
        <div class='auth-inner'>
            <!-- brand logo -->
            <router-link class='brand-logo d-flex align-center' to='/'>
                <v-img :src='appLogo' alt='logo' class='me-3 ' contain max-height='30px' max-width='30px'></v-img>
                <h2 class='text--primary'> {{ appName }} </h2>
            </router-link>
            <!--/ brand logo -->
            <v-row class='auth-row ma-0'>
                <v-col class='d-none d-lg-block position-relative overflow-hidden pa-0' lg='8'>
                    <div class='auth-illustrator-wrapper'>
                        <!-- triangle bg -->
                        <img :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                            class='auth-mask-bg' height='362' />
                        <!-- tree -->
                        <v-img class='auth-tree' height='226' src='@/assets/images/misc/tree-4.png' width='300'></v-img>
                        <!-- 3d character -->
                        <div class='d-flex justify-center align-center h-full pa-16 pe-0'>
                            <v-img :src="require(`@/assets/taxi.webp`)" class='auth-3d-group rounded-lg elevation-16' cover
                                height='400' max-width='60%'></v-img>
                        </div>
                    </div>
                </v-col>
                <v-col class='d-flex align-center auth-bg pa-10 pb-0' lg='4'>
                    <v-row>
                        <v-col class='mx-auto' cols='12' lg='12' md='6' sm='8'>
                            <v-card flat>
                                <v-card-text>
                                    <p class='text-2xl font-weight-semibold text--primary mb-2'>欢迎登录好出行管理后台</p>
                                </v-card-text>
                                <!-- login form -->
                                <v-card-text>
                                    <v-form ref='loginForm' @submit.prevent='handleFormSubmit'>
                                        <v-text-field v-model='email' :error-messages='errorMessages.username'
                                            :rules='[validators.required, validators.emailValidator]' class='mb-6'
                                            hide-details='auto' label='用户名' outlined placeholder='用户名'></v-text-field>
                                        <v-text-field v-model='password'
                                            :append-icon='isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline'
                                            :error-messages='errorMessages.password' :rules='[validators.required]'
                                            :type="isPasswordVisible ? 'text' : 'password'" class='mb-2' hide-details='auto'
                                            label='密码' outlined placeholder='密码'
                                            @click:append='isPasswordVisible = !isPasswordVisible'></v-text-field>
                                        <v-btn block class='mt-6' color='primary' type='submit'>登录</v-btn>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { emailValidator, required } from '@core/utils/validation'
import axios from '@axios'
import md5 from 'js-md5'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
    setup() {
        const loginForm = ref(null)
        const vm = getCurrentInstance().proxy
        const { router } = useRouter()
        const isPasswordVisible = ref(false)
        const email = ref('')
        const password = ref('')
        const errorMessages = ref({
            username: '',
            password: '',
        })
        const authMe = (token) => {
            let strings = token.split("."); //截取token，获取载体
            var userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
            console.log(userinfo);
            const user = {
                id: userinfo.sub,
                fullName: userinfo.nn,
                username: userinfo.un,
                avatar: require('@/assets/images/avatars/1.png'),
                email: 'admin@www.maxnotech.com',
                client: userinfo.cli,
                role: 'admin',
                ability: [
                    {
                        action: 'manage',
                        subject: 'all',
                    },
                ],
            }
            const { ability: userAbility } = user
            vm.$ability.update(userAbility) // 权限相关
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))
            router.push('/')
        }
        const handleFormSubmit = () => {
            const isFormValid = loginForm.value.validate()
            if (!isFormValid) return
            axios.post('/common/v1/auth', {
                type: "admin_company",
                login: email.value,
                // password: md5(password.value),
                password: password.value,
            }).then(response => {
                const { code, msg, data } = response.data
                if (code !== 200) {
                    errorMessages.value.username = msg
                    return
                }
                localStorage.setItem('accessToken', data)
                authMe(data)
            }).catch(error => {
                console.error('Oops, Unable to login!')
                console.log('error :>> ', error)
                errorMessages.value.username = error.response.data.error
            })
        }
        return {
            handleFormSubmit, isPasswordVisible, email, password, errorMessages, icons: { mdiEyeOutline, mdiEyeOffOutline }, validators: { required, emailValidator },
            appName: themeConfig.app.name, appLogo: themeConfig.app.logo,
            loginForm,
        }
    },
} </script>
<style lang='scss' scoped> @import '@core/preset/preset/pages/auth.scss';
</style>
